import React from "react"

export const IconGraphicDesign = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.158"
    height="29.355"
    viewBox="0 0 9.158 29.355"
  >
    <g transform="translate(-184.127 -2377.282)">
      <path
        d="M186.3,2412.569c1.169-1.663.322-3.052-.525-4.438-1.738-2.849-1.918-4.14.145-6.763l5.505.018.637.6a3.45,3.45,0,0,1,1.044,1.789c.543,2.521-3.455,7.6-5.285,9.162C186.773,2413.834,185.409,2413.84,186.3,2412.569Z"
        transform="translate(-0.09 -7.216)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M186.214,2412.475h0Zm-.273-10.99c-1.939,2.5-1.738,3.7-.068,6.433.87,1.426,1.767,2.9.518,4.676h0c-.263.372-.281.58-.23.622.132.121.8,0,1.435-.536,1.843-1.57,5.725-6.58,5.214-8.955a3.3,3.3,0,0,0-.982-1.681l-.574-.541-5.313-.019Zm.4,12.206a.685.685,0,0,1-.474-.153c-.187-.17-.31-.517.167-1.192,1.086-1.539.3-2.83-.532-4.2-1.754-2.874-1.977-4.292.16-7.007a.211.211,0,0,1,.169-.085h0l5.505.018a.234.234,0,0,1,.146.06l.637.6a3.724,3.724,0,0,1,1.107,1.906c.569,2.636-3.547,7.829-5.356,9.369A2.611,2.611,0,0,1,186.345,2413.691Z"
        transform="translate(0 -7.122)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M186.742,2414.019a.227.227,0,0,1-.134-.05.216.216,0,0,1-.035-.305,4.334,4.334,0,0,0,1.011-3.762,14.226,14.226,0,0,0-.741-2.38,5.109,5.109,0,0,1-.23-4.377.216.216,0,0,1,.4.171c-.693,1.63-.283,2.695.235,4.053a14.288,14.288,0,0,1,.763,2.448,4.733,4.733,0,0,1-1.1,4.115A.216.216,0,0,1,186.742,2414.019Z"
        transform="translate(-0.624 -7.71)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M187.112,2413.977a.218.218,0,0,1-.154-.069.213.213,0,0,1,0-.3c2.9-2.83,3.033-7.818,2.6-9.417a.216.216,0,1,1,.417-.117c.453,1.674.315,6.886-2.716,9.844A.2.2,0,0,1,187.112,2413.977Z"
        transform="translate(-0.829 -7.978)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M187.282,2414.066a.215.215,0,0,1-.081-.414c.02-.011.043-.019.066-.029a.211.211,0,0,1,.067-.184c2.5-2.306,5.851-7.032,4.214-9.892a.217.217,0,0,1,.081-.293.214.214,0,0,1,.295.08c1.64,2.867-1.1,6.952-3.154,9.246a14.616,14.616,0,0,0,4.026-6.665,3.112,3.112,0,0,0-.424-2.759.217.217,0,1,1,.314-.3,3.4,3.4,0,0,1,.532,3.142c-.666,3.081-3.62,7.142-5.856,8.048A.21.21,0,0,1,187.282,2414.066Z"
        transform="translate(-0.88 -7.642)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M192.835,2398.847l0-.049v-6.046a1.8,1.8,0,0,1-1.031,1.388,5.569,5.569,0,0,1-4.508.233c-.691-.28-1.462-.8-1.511-1.621v6.046l0,.049C186.053,2401.422,192.564,2401.434,192.835,2398.847Z"
        transform="translate(-0.497 -4.635)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M192.743,2398.753h0Zm-6.832-5.083v5.033c.123,1.224,1.8,1.766,3.3,1.772h.007c1.507,0,3.181-.541,3.309-1.74l0-.043v-5.023a2.928,2.928,0,0,1-.707.565,5.759,5.759,0,0,1-4.695.244,3.222,3.222,0,0,1-1.217-.809Zm3.308,7.237h-.008c-1.747-.005-3.578-.662-3.727-2.13l0-.056v-6.063a.219.219,0,0,1,.209-.218.213.213,0,0,1,.222.207c.046.773.885,1.228,1.378,1.435a5.353,5.353,0,0,0,4.317-.224,1.581,1.581,0,0,0,.923-1.211.228.228,0,0,1,.222-.207.221.221,0,0,1,.21.218v6.045c0,.008,0,.061,0,.068v.006C192.8,2400.251,190.97,2400.908,189.219,2400.908Z"
        transform="translate(-0.405 -4.542)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M191.806,2391.235a1.829,1.829,0,0,0,1.031-1.393v-9.267c-.071,1.132-1.486,1.712-2.439,1.889a5.105,5.105,0,0,1-4.078-.859,1.518,1.518,0,0,1-.534-1.03v9.267c.026.82.894,1.387,1.586,1.649A5.567,5.567,0,0,0,191.806,2391.235Z"
        transform="translate(-0.497 -0.987)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M191.714,2391.141h0Zm-5.8-9.648v8.255c.02.67.76,1.188,1.446,1.448a5.373,5.373,0,0,0,4.248-.245,1.667,1.667,0,0,0,.924-1.211v-8.248a4.014,4.014,0,0,1-2.185,1.089,5.329,5.329,0,0,1-4.263-.914,2.439,2.439,0,0,1-.171-.176Zm3.307,10.465a5.7,5.7,0,0,1-2.015-.354c-.394-.152-1.688-.73-1.723-1.849v-9.273a.217.217,0,0,1,.209-.219.223.223,0,0,1,.222.2,1.267,1.267,0,0,0,.464.883,4.867,4.867,0,0,0,3.891.811c1.006-.188,2.2-.743,2.264-1.694a.219.219,0,0,1,.215-.2h.007a.219.219,0,0,1,.21.219v9.267a1.994,1.994,0,0,1-1.139,1.576h0A5.347,5.347,0,0,1,189.218,2391.958Z"
        transform="translate(-0.405 -0.893)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M189.311,2381.667a5.143,5.143,0,0,0,2.495-.6,1.52,1.52,0,0,0,0-2.88,5.5,5.5,0,0,0-4.988,0,1.521,1.521,0,0,0,0,2.88A5.141,5.141,0,0,0,189.311,2381.667Z"
        transform="translate(-0.497 -0.093)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M189.219,2377.723a4.861,4.861,0,0,0-2.385.565,1.31,1.31,0,0,0,0,2.5,5.319,5.319,0,0,0,4.771,0,1.309,1.309,0,0,0,0-2.5,4.869,4.869,0,0,0-2.386-.565Zm0,4.066a5.3,5.3,0,0,1-2.6-.623,1.733,1.733,0,0,1,0-3.257,5.773,5.773,0,0,1,5.2,0,1.732,1.732,0,0,1,0,3.257A5.3,5.3,0,0,1,189.219,2381.79Z"
        transform="translate(-0.405 -0.003)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M192.432,2379.2c0-2.139-5.634-2.139-5.634,0S192.43,2381.338,192.432,2379.2Z"
        transform="translate(-0.8 -0.093)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M189.523,2377.715h0c-1.291,0-2.6.481-2.6,1.388s1.309,1.387,2.6,1.387h0c1.292,0,2.6-.473,2.6-1.387s-1.309-1.388-2.6-1.388Zm0,3.208h0c-1.46,0-3.031-.567-3.031-1.82s1.571-1.82,3.031-1.82h0c1.461,0,3.032.572,3.032,1.82h0C192.555,2380.356,190.984,2380.923,189.523,2380.923Z"
        transform="translate(-0.709 0)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M186.782,2414.8c.614-.81.291-1.766-.009-2.678-.331-1,1.523.4,2.84-.118,1.61-.626,1.279-2.435,2.686-2.7.481-.092.933-.139.736.324a17.492,17.492,0,0,1-4.527,5.849C187.455,2416.378,185.886,2416.075,186.782,2414.8Z"
        transform="translate(-0.716 -9.578)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M186.863,2414.839c-.112.158-.347.546-.144.737a1.392,1.392,0,0,0,1.557-.348,17.188,17.188,0,0,0,4.469-5.771.235.235,0,0,0,.029-.1c-.008.019-.121,0-.529.079-.6.111-.826.541-1.112,1.1a2.894,2.894,0,0,1-1.536,1.59,3.539,3.539,0,0,1-2.154-.11,4,4,0,0,0-.582-.134.848.848,0,0,0,.022.092c.31.937.657,1.978-.02,2.874Zm.268,1.284a1.021,1.021,0,0,1-.7-.231c-.153-.139-.45-.549.083-1.3,0-.007,0-.007,0-.007.544-.718.262-1.564-.036-2.464a.558.558,0,0,1,.043-.555c.2-.2.529-.11,1.034.024a3.2,3.2,0,0,0,1.884.128,2.51,2.51,0,0,0,1.308-1.387c.3-.578.613-1.169,1.417-1.321.4-.073.776-.124.956.1a.482.482,0,0,1,.018.518,17.614,17.614,0,0,1-4.586,5.928A2.261,2.261,0,0,1,187.131,2416.123Z"
        transform="translate(-0.623 -9.486)"
        fill="#195bb6"
        fillRule="evenodd"
      />
    </g>
  </svg>
)
