import React from "react"

export const IconApp = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 43 42"
    fill="red"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i)">
      <path
        d="M21.3049 36.0105L10.6484 29.9988V41.9998L21.3049 36.0105Z"
        fill="#BDE0F7"
      />
      <path
        d="M31.9689 30.0138L21.3125 24.0095V36.0106L31.9689 30.0138Z"
        fill="#204E86"
      />
      <path
        d="M10.6484 29.999L21.3049 36.0107V24.0022L10.6484 29.999Z"
        fill="#337AC8"
      />
      <path
        d="M31.9766 18.0129L42.633 24.0172V12.0161L31.9766 18.0129Z"
        fill="#204E86"
      />
      <path
        d="M21.3203 12.0011L31.9768 18.0128V6.01172L21.3203 12.0011Z"
        fill="#BDE0F7"
      />
      <path
        d="M42.6486 12.0161L31.9922 6.00439V18.0129L42.6486 12.0161Z"
        fill="#337AC8"
      />
      <path
        d="M10.6641 5.96697L21.3205 11.9712V0L10.6641 5.96697Z"
        fill="#204E86"
      />
      <path d="M0 11.9862L10.6565 17.9979V5.96704L0 11.9862Z" fill="#BDE0F7" />
      <path
        d="M21.3205 12.0011L10.6641 5.96704V17.9979L21.3205 12.0011Z"
        fill="#337AC8"
      />
      <path
        d="M31.9697 30.0139L42.6262 36.0256V24.0171L31.9697 30.0139Z"
        fill="#BDE0F7"
      />
    </g>
    <defs>
      <filter
        id="filter0_i"
        x="0"
        y="0"
        width="42.6486"
        height="44.9998"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
    </defs>
  </svg>
)
