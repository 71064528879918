import React from "react"

export const IconWhitePage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.908"
    height="25.967"
    viewBox="0 0 19.908 25.967"
  >
    <path
      d="M2914.474,252.9a1.819,1.819,0,0,1-1.818-1.818V244.5h-10.472a1.039,1.039,0,0,0-1.039,1.039v23.89a1.039,1.039,0,0,0,1.039,1.039h17.831a1.039,1.039,0,0,0,1.039-1.039V252.9Zm3.117,12.853a.26.26,0,0,1-.26.26h-12.464a.26.26,0,0,1-.26-.26v-1.168a.26.26,0,0,1,.26-.26h12.464a.26.26,0,0,1,.26.26Zm0-4.111a.26.26,0,0,1-.26.26h-12.464a.26.26,0,0,1-.26-.26V260.47a.26.26,0,0,1,.26-.26h12.464a.26.26,0,0,1,.26.26Zm0-4.111a.26.26,0,0,1-.26.26h-12.464a.26.26,0,0,1-.26-.26v-1.169a.26.26,0,0,1,.26-.26h12.464a.26.26,0,0,1,.26.26Zm3.462-5.409h-6.579a1.038,1.038,0,0,1-1.039-1.039V244.5Z"
      transform="translate(-2901.145 -244.5)"
      fill="#fff"
    />
  </svg>
)
