import React from "react"

export const IconMobileNavbarV1 = () => (
  <svg
    width="29"
    height="30"
    viewBox="0 0 29 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8996 3H5.09961C4.49961 3 4.09961 2.5 4.09961 1.9V1.1C4.09961 0.5 4.49961 0 5.09961 0H22.8996C23.4996 0 23.9996 0.5 23.9996 1.1V2C23.9996 2.5 23.4996 3 22.8996 3Z"
      fill="#616161"
    />
    <path
      d="M22.9 10H1.1C0.5 10 0 9.5 0 9V8.1C0 7.5 0.5 7 1.1 7H22.9C23.5 7 24 7.5 24 8.1V9C24 9.5 23.5 10 22.9 10Z"
      fill="#616161"
    />
    <path
      d="M22.9 16.9999H9.1C8.5 16.9999 8 16.4999 8 15.8999V14.9999C8 14.3999 8.5 13.8999 9.1 13.8999H22.9C23.5 13.8999 24 14.3999 24 14.9999V15.9999C24 16.5999 23.5 16.9999 22.9 16.9999Z"
      fill="#616161"
    />
  </svg>
)
