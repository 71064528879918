import React from "react"

export const IconProgrammingTech = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.159"
    height="28.405"
    viewBox="0 0 19.159 28.405"
  >
    <g transform="translate(-1149.296 -1073.995)">
      <path
        d="M1152.263,1129.756l-1.957-1.128a1,1,0,0,1-.4-.881l.006-11.508,1.957,1.13-.005,11.509A.99.99,0,0,0,1152.263,1129.756Z"
        transform="translate(-0.398 -27.662)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M1149.721,1116.205v11.14c.036.381.043.49.318.716l1.242.714c-.013-.084-.018-.177-.029-.282l.006-11.4-1.537-.885Zm2.143,13.36a.243.243,0,0,1-.109-.027l-1.957-1.131a1.2,1.2,0,0,1-.5-1.043l.006-11.528a.209.209,0,0,1,.1-.185.229.229,0,0,1,.215,0l1.955,1.131a.213.213,0,0,1,.108.184l-.005,11.509c.036.38.042.488.318.714a.214.214,0,0,1,.042.286A.211.211,0,0,1,1151.865,1129.566Z"
        transform="translate(0 -27.26)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1151.877,1090.108l-1.957-1.13v-3.729a3.013,3.013,0,0,1,1.364-2.357l14.052-8.1a.989.989,0,0,1,.96-.093l1.955,1.129a1,1,0,0,0-.961.094l-14.051,8.1a3.009,3.009,0,0,0-1.362,2.357Z"
        transform="translate(-0.409 -0.401)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1149.732,1088.453l1.537.884v-3.36a3.185,3.185,0,0,1,1.458-2.536l14.059-8.11c.1-.047.192-.089.275-.121l-1.271-.733c-.3-.11-.4-.067-.762.1l-14.035,8.1a2.761,2.761,0,0,0-1.254,2.183l-.007,3.6Zm1.747,1.467a.225.225,0,0,1-.108-.031l-1.955-1.128a.208.208,0,0,1-.1-.185v-3.729a3.191,3.191,0,0,1,1.458-2.536l14.059-8.11a1.22,1.22,0,0,1,1.146-.106l1.986,1.143a.21.21,0,0,1,.083.275.207.207,0,0,1-.264.109c-.329-.125-.43-.08-.8.087l-14.033,8.1a2.762,2.762,0,0,0-1.255,2.182v3.72a.211.211,0,0,1-.109.184A.227.227,0,0,1,1151.479,1089.92Z"
        transform="translate(-0.01)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1156.951,1086.155l14.052-8.1c.75-.432,1.362-.08,1.362.787l-.005,3.726-16.77,9.678v-3.73A3.014,3.014,0,0,1,1156.951,1086.155Z"
        transform="translate(-4.121 -2.535)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1156.662,1085.932a2.823,2.823,0,0,0-1.255,2.172v3.36l16.345-9.432v-3.6a.623.623,0,0,0-1.038-.6l-14.051,8.1Zm-1.471,6.115a.222.222,0,0,1-.108-.031.2.2,0,0,1-.1-.182v-3.73a3.248,3.248,0,0,1,1.47-2.541h0l14.046-8.1a1.038,1.038,0,0,1,1.68.971v3.725a.213.213,0,0,1-.109.183l-16.771,9.679A.229.229,0,0,1,1155.191,1092.048Z"
        transform="translate(-3.723 -2.128)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1172.349,1091.474v11.51a3.01,3.01,0,0,1-1.362,2.357c-5.153,2.973-8.9,5.131-14.052,8.1-.75.432-1.362.08-1.362-.785l.005-11.509Z"
        transform="translate(-4.111 -11.446)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M1155.4,1100.872l-.006,11.385a.624.624,0,0,0,1.038.6l14.051-8.106a2.822,2.822,0,0,0,1.255-2.172l.005-11.143-16.345,9.434Zm.535,12.57a.92.92,0,0,1-.463-.121,1.158,1.158,0,0,1-.5-1.063l.005-11.509a.21.21,0,0,1,.1-.184l16.777-9.679a.211.211,0,0,1,.21,0,.218.218,0,0,1,.109.185l-.008,11.51a3.253,3.253,0,0,1-1.463,2.541l-14.053,8.1A1.443,1.443,0,0,1,1155.932,1113.442Z"
        transform="translate(-3.713 -11.042)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1184.193,1092.315c.368-.21.66-.039.66.38a1.444,1.444,0,0,1-.66,1.138c-.359.208-.654.04-.654-.38A1.456,1.456,0,0,1,1184.193,1092.315Z"
        transform="translate(-22.423 -11.938)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1183.786,1091.911h0Zm.3.124a.39.39,0,0,0-.186.061h0a1.232,1.232,0,0,0-.554.953.275.275,0,0,0,.09.24.279.279,0,0,0,.253-.045,1.246,1.246,0,0,0,.545-.953c0-.121-.029-.211-.083-.242a.155.155,0,0,0-.065-.014Zm-.59,1.7a.564.564,0,0,1-.275-.073.68.68,0,0,1-.3-.609,1.659,1.659,0,0,1,.768-1.322h0a.6.6,0,0,1,.971.564,1.648,1.648,0,0,1-.761,1.321A.806.806,0,0,1,1183.491,1093.731Z"
        transform="translate(-22.015 -11.534)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1190.755,1088.529c.359-.208.655-.038.655.38a1.455,1.455,0,0,1-.655,1.137c-.366.21-.66.04-.66-.38A1.45,1.45,0,0,1,1190.755,1088.529Z"
        transform="translate(-26.716 -9.459)"
        fill="#195bb5"
        fillRule="evenodd"
      />
      <path
        d="M1190.356,1088.127Zm.287.123a.427.427,0,0,0-.187.061h0a1.254,1.254,0,0,0-.552.953.28.28,0,0,0,.091.242c.054.031.144.013.251-.047a1.235,1.235,0,0,0,.552-.953.279.279,0,0,0-.089-.24.118.118,0,0,0-.067-.016Zm-.588,1.7a.541.541,0,0,1-.275-.072.673.673,0,0,1-.3-.609,1.665,1.665,0,0,1,.762-1.32h0a.61.61,0,0,1,.978.563,1.658,1.658,0,0,1-.769,1.322A.8.8,0,0,1,1190.056,1089.946Z"
        transform="translate(-26.318 -9.057)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1197.31,1084.743c.36-.21.653-.04.653.38a1.455,1.455,0,0,1-.653,1.138c-.365.21-.66.04-.66-.38A1.449,1.449,0,0,1,1197.31,1084.743Z"
        transform="translate(-31.009 -6.979)"
        fill="#195bb5"
        fillRule="evenodd"
      />
      <path
        d="M1197.2,1084.464a.4.4,0,0,0-.186.061,1.246,1.246,0,0,0-.546.953c0,.119.031.212.085.242a.284.284,0,0,0,.251-.047,1.234,1.234,0,0,0,.552-.953.287.287,0,0,0-.089-.243.14.14,0,0,0-.067-.014Zm-.583,1.7a.534.534,0,0,1-.275-.073.661.661,0,0,1-.3-.609,1.672,1.672,0,0,1,.761-1.322.611.611,0,0,1,.979.565,1.663,1.663,0,0,1-.769,1.322A.8.8,0,0,1,1196.617,1086.16Z"
        transform="translate(-30.611 -6.577)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1170.816,1123.745v-.608l2.8-1.617v.608Z"
        transform="translate(-14.092 -31.121)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1170.615,1122.857v.116l2.376-1.371v-.116l-2.376,1.371Zm-.21.7a.226.226,0,0,1-.108-.027.216.216,0,0,1-.108-.184v-.608a.216.216,0,0,1,.108-.184l2.8-1.615a.2.2,0,0,1,.211,0,.212.212,0,0,1,.107.183v.607a.215.215,0,0,1-.107.185l-2.8,1.615A.183.183,0,0,1,1170.4,1123.553Z"
        transform="translate(-13.681 -30.718)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1161.757,1123.731v-.942l2.022-2.091-2.022.255v-.933l2.9-.225v.8Z"
        transform="translate(-8.16 -29.992)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1161.56,1122.472v.313l2.478-2.677v-.487l-2.478.19v.5l1.787-.225a.212.212,0,0,1,.217.116.218.218,0,0,1-.041.243l-1.963,2.029Zm-.211,1.067a.226.226,0,0,1-.078-.014.211.211,0,0,1-.138-.2v-.942a.24.24,0,0,1,.06-.149l1.6-1.655-1.422.179a.216.216,0,0,1-.168-.051.233.233,0,0,1-.073-.161v-.933a.217.217,0,0,1,.2-.213l2.9-.222a.189.189,0,0,1,.156.056.2.2,0,0,1,.072.155v.8a.21.21,0,0,1-.06.145l-2.9,3.135A.216.216,0,0,1,1161.349,1123.539Z"
        transform="translate(-7.751 -29.588)"
        fill="#195bb6"
        fillRule="evenodd"
      />
    </g>
  </svg>
)
