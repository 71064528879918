import React from "react"

export const IconMarketing = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.536"
    height="32.533"
    viewBox="0 0 27.536 32.533"
  >
    <g id="Group_42" transform="translate(-984.131 -1074.461)">
      <path
        id="Path_157"
        d="M992.6,1125.515l-7.849-4.532,19.19-11.08,7.851,4.532Z"
        transform="translate(-0.371 -21.269)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_158"
        d="M984.87,1120.613l7.357,4.247,18.7-10.795-7.358-4.249-18.7,10.8Zm7.357,4.777a.232.232,0,0,1-.122-.033l-7.851-4.53a.251.251,0,0,1-.124-.214.248.248,0,0,1,.124-.213l19.19-11.08a.249.249,0,0,1,.247,0l7.851,4.532a.249.249,0,0,1,.124.214.253.253,0,0,1-.124.214l-19.193,11.078A.232.232,0,0,1,992.227,1125.39Z"
        transform="translate(0 -20.9)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_159"
        d="M992.6,1144.646l-7.849-4.532v-2.5l7.849,4.532Z"
        transform="translate(-0.371 -37.899)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_160"
        d="M984.623,1139.6l7.359,4.247v-1.932l-7.359-4.247v1.932Zm7.6,4.922a.253.253,0,0,1-.122-.034l-7.851-4.534a.246.246,0,0,1-.124-.211v-2.5a.247.247,0,0,1,.371-.214l7.849,4.532a.244.244,0,0,1,.124.214v2.5a.244.244,0,0,1-.124.214A.252.252,0,0,1,992.227,1144.523Z"
        transform="translate(0 -37.529)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_161"
        d="M1004.378,1132.317l19.193-11.08v2.5l-19.193,11.08Z"
        transform="translate(-12.151 -28.072)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_162"
        d="M1004.257,1132.089v1.932l18.7-10.795v-1.932l-18.7,10.795Zm-.247,2.607a.253.253,0,0,1-.122-.034.248.248,0,0,1-.124-.214v-2.5a.248.248,0,0,1,.124-.214l19.191-11.08a.247.247,0,0,1,.371.214v2.5a.244.244,0,0,1-.124.211l-19.193,11.082A.252.252,0,0,1,1004.01,1134.7Z"
        transform="translate(-11.783 -27.701)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_163"
        d="M1011.318,1103.988l-4.31-2.487v-19.064l4.31,2.487Z"
        transform="translate(-13.729 -4.786)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        id="Path_164"
        d="M1006.885,1100.986l3.815,2.2V1084.7l-3.815-2.2v18.5Zm4.062,2.879a.256.256,0,0,1-.123-.034l-4.31-2.489a.245.245,0,0,1-.123-.212v-19.064a.247.247,0,0,1,.123-.214.249.249,0,0,1,.247,0l4.31,2.487a.25.25,0,0,1,.124.213v19.065a.249.249,0,0,1-.124.214A.262.262,0,0,1,1010.947,1103.865Z"
        transform="translate(-13.358 -4.415)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_165"
        d="M1011.318,1080.5l-4.31-2.487,5.1-2.944,4.31,2.489Z"
        transform="translate(-13.729 -0.367)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        id="Path_166"
        d="M1007.129,1077.65l3.817,2.2,4.6-2.657-3.817-2.2-4.6,2.659Zm3.817,2.734a.232.232,0,0,1-.123-.034l-4.31-2.489a.245.245,0,0,1,0-.425l5.1-2.942a.243.243,0,0,1,.245,0l4.312,2.489a.243.243,0,0,1,.124.211.249.249,0,0,1-.124.214l-5.1,2.941A.237.237,0,0,1,1010.947,1080.384Z"
        transform="translate(-13.358)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_167"
        d="M1003.67,1111.192l-4.31-2.489-5.1,2.944,4.31,2.487Z"
        transform="translate(-6.081 -20.549)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_168"
        d="M994.391,1111.28l3.816,2.2,4.6-2.657-3.818-2.2-4.6,2.659Zm3.816,2.735a.247.247,0,0,1-.121-.034l-4.31-2.49a.244.244,0,0,1,0-.425l5.093-2.942a.248.248,0,0,1,.247,0l4.31,2.49a.246.246,0,0,1,.124.211.25.25,0,0,1-.124.214l-5.1,2.942A.258.258,0,0,1,998.207,1114.015Z"
        transform="translate(-5.714 -20.183)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_169"
        d="M998.574,1118.552l-4.31-2.487v8.557l4.31,2.489Z"
        transform="translate(-6.081 -24.968)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_170"
        d="M994.144,1124.107l3.816,2.2v-7.989l-3.816-2.2v7.989Zm4.063,2.879a.243.243,0,0,1-.121-.034l-4.31-2.489a.245.245,0,0,1-.124-.214v-8.557a.242.242,0,0,1,.124-.214.233.233,0,0,1,.245,0l4.31,2.487a.245.245,0,0,1,.124.214v8.559a.244.244,0,0,1-.124.214A.254.254,0,0,1,998.207,1126.986Z"
        transform="translate(-5.714 -24.595)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_171"
        d="M1024.062,1106.593l-4.31-2.489v-12.425l4.31,2.487Z"
        transform="translate(-21.378 -10.333)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_172"
        d="M1019.626,1103.591l3.818,2.2v-11.857l-3.818-2.2v11.855Zm4.065,2.879a.259.259,0,0,1-.124-.033l-4.31-2.49a.245.245,0,0,1-.123-.213v-12.425a.246.246,0,0,1,.368-.214l4.312,2.489a.25.25,0,0,1,.124.212l0,12.427a.245.245,0,0,1-.124.214A.256.256,0,0,1,1023.691,1106.47Z"
        transform="translate(-21.007 -9.962)"
        fill="#1a87c7"
        fillRule="evenodd"
      />
      <path
        id="Path_173"
        d="M1024.062,1089.751l-4.31-2.487,5.1-2.942,4.31,2.49Z"
        transform="translate(-21.378 -5.917)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_174"
        d="M1019.873,1086.894l3.818,2.2,4.6-2.657-3.815-2.2-4.6,2.657Zm3.818,2.734a.241.241,0,0,1-.124-.033l-4.31-2.487a.247.247,0,0,1,0-.428l5.1-2.94a.235.235,0,0,1,.245,0l4.31,2.487a.245.245,0,0,1,.124.214.248.248,0,0,1-.124.214l-5.094,2.939A.24.24,0,0,1,1023.691,1089.628Z"
        transform="translate(-21.007 -5.548)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_175"
        d="M1030.53,1105.914v-12.427l5.1-2.94v12.425Z"
        transform="translate(-27.846 -9.654)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_176"
        d="M1030.4,1093.259v11.857l4.6-2.657V1090.6l-4.6,2.657Zm-.246,12.532a.255.255,0,0,1-.124-.034.248.248,0,0,1-.123-.213v-12.427a.248.248,0,0,1,.123-.212l5.1-2.942a.246.246,0,0,1,.369.214V1102.6a.244.244,0,0,1-.124.214l-5.1,2.942A.251.251,0,0,1,1030.159,1105.79Z"
        transform="translate(-27.475 -9.283)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_177"
        d="M1010.138,1123.487v-8.559l-5.1,2.942v8.559Z"
        transform="translate(-12.55 -24.285)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        id="Path_178"
        d="M1009.768,1123.117h0Zm-4.848-5.475v7.989l4.6-2.657v-7.989l-4.6,2.657Zm-.247,8.664a.252.252,0,0,1-.247-.248V1117.5a.249.249,0,0,1,.126-.214l5.094-2.941a.247.247,0,0,1,.371.213v8.559a.25.25,0,0,1-.123.214l-5.1,2.942A.259.259,0,0,1,1004.672,1126.307Z"
        transform="translate(-12.179 -23.916)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        id="Path_179"
        d="M1017.786,1103.3V1084.24l5.1-2.942v19.065Z"
        transform="translate(-20.198 -4.103)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        id="Path_180"
        d="M1017.663,1084.013v18.495l4.6-2.656v-18.495l-4.6,2.657Zm-.248,19.17a.254.254,0,0,1-.123-.033.25.25,0,0,1-.124-.214v-19.065a.25.25,0,0,1,.124-.214l5.1-2.94a.235.235,0,0,1,.245,0,.24.24,0,0,1,.124.211v19.065a.247.247,0,0,1-.124.213l-5.094,2.942A.26.26,0,0,1,1017.415,1103.183Z"
        transform="translate(-19.827 -3.734)"
        fill="#195bb6"
        fillRule="evenodd"
      />
    </g>
  </svg>
)
