import React from "react"

export const IconVideo = ({ color, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.765"
    height="29.781"
    viewBox="0 0 21.765 29.781"
  >
    <g transform="translate(-1119.484 -1209.539)">
      <path
        d="M1147.375,1239.7l-1.77-1.022v1.765l1.77,1.021Z"
        transform="translate(-10.224 -11.405)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1145.684,1240.215l1.387.8v-1.33l-1.387-.8v1.331Zm1.579,1.317a.216.216,0,0,1-.1-.026l-1.753-1.02a.179.179,0,0,1-.1-.163v-1.765a.177.177,0,0,1,.1-.161.164.164,0,0,1,.175,0l1.77,1.02a.179.179,0,0,1,.1.163v1.763a.176.176,0,0,1-.1.162A.209.209,0,0,1,1147.263,1241.532Z"
        transform="translate(-10.111 -11.285)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1147.375,1214.576l-1.77-1.021v1.477l1.77,1.021Z"
        transform="translate(-10.224 -1.572)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1145.684,1214.8l1.387.805v-1.044l-1.387-.805v1.045Zm1.579,1.317a.2.2,0,0,1-.1-.025l-1.753-1.021a.177.177,0,0,1-.1-.163v-1.476a.18.18,0,0,1,.1-.163.165.165,0,0,1,.175,0l1.77,1.022a.175.175,0,0,1,.1.162v1.476a.175.175,0,0,1-.1.162A.2.2,0,0,1,1147.263,1216.12Z"
        transform="translate(-10.111 -1.451)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1139.787,1217l-1.77-1.021-11.8,6.815,1.77,1.022Z"
        transform="translate(-2.635 -2.521)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1126.478,1222.676l1.387.805,11.433-6.6-1.4-.8-11.416,6.6Zm1.387,1.21a.223.223,0,0,1-.1-.026l-1.769-1.022a.184.184,0,0,1,0-.324l11.816-6.817a.163.163,0,0,1,.175,0l1.77,1.022a.185.185,0,0,1,0,.324l-11.8,6.816A.219.219,0,0,1,1127.864,1223.886Z"
        transform="translate(-2.512 -2.401)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1151.3,1210.868l-1.786-1.022-3.907,2.257,1.77,1.021Z"
        transform="translate(-10.224 -0.12)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1145.875,1211.983l1.388.805,3.539-2.041-1.4-.8-3.524,2.041Zm1.388,1.209a.2.2,0,0,1-.1-.026l-1.753-1.021a.186.186,0,0,1,0-.325l3.906-2.256a.171.171,0,0,1,.176,0l1.77,1.023a.178.178,0,0,1,.1.162.18.18,0,0,1-.1.163l-3.907,2.257A.192.192,0,0,1,1147.263,1213.193Z"
        transform="translate(-10.111 0)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1126.613,1237.651v-1.762l11.8-6.817v1.764l3.923-2.256v-17.055l-3.923,2.256v1.476l-11.8,6.816V1220.6l-3.906,2.258v17.055Z"
        transform="translate(-1.261 -0.777)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1126.49,1237.53Zm-3.715-14.688v16.621l3.524-2.04v-1.655a.174.174,0,0,1,.1-.162l11.8-6.817a.2.2,0,0,1,.191,0,.179.179,0,0,1,.1.163v1.438l3.54-2.04v-16.622l-3.54,2.041v1.369a.175.175,0,0,1-.1.162l-11.8,6.816a.2.2,0,0,1-.191,0,.178.178,0,0,1-.1-.163V1220.8l-3.524,2.041Zm-.191,17.134a.225.225,0,0,1-.1-.025.178.178,0,0,1-.1-.163v-17.055a.177.177,0,0,1,.1-.162l3.907-2.256a.194.194,0,0,1,.191,0,.175.175,0,0,1,.1.161v1.153l11.417-6.6v-1.369a.212.212,0,0,1,.1-.162l3.923-2.257a.163.163,0,0,1,.176,0,.178.178,0,0,1,.1.162v17.055a.177.177,0,0,1-.1.161l-3.907,2.258a.191.191,0,0,1-.191,0,.214.214,0,0,1-.1-.162v-1.438l-11.417,6.6v1.655a.178.178,0,0,1-.1.163l-3.907,2.258A.218.218,0,0,1,1122.584,1239.976Z"
        transform="translate(-1.138 -0.656)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1129.676,1226.673l11.05-6.376v11.094l-11.05,6.377Z"
        transform="translate(-3.989 -4.211)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M1129.744,1226.661v10.661l10.667-6.159V1220.5l-10.667,6.16Zm-.191,11.173a.189.189,0,0,1-.1-.026.212.212,0,0,1-.1-.16v-11.095a.212.212,0,0,1,.1-.162l11.05-6.377a.166.166,0,0,1,.175,0,.181.181,0,0,1,.1.163v11.094a.179.179,0,0,1-.1.163l-11.034,6.375A.19.19,0,0,1,1129.553,1237.834Z"
        transform="translate(-3.866 -4.089)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1134.182,1230.83l6.873-.056-6.873,7.876Z"
        transform="translate(-5.753 -8.311)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1134.25,1230.9v7.134l6.25-7.186-6.25.052Zm-.192,7.821a.124.124,0,0,1-.063-.013.184.184,0,0,1-.128-.175v-7.819a.187.187,0,0,1,.192-.187l6.873-.057a.219.219,0,0,1,.159.11.181.181,0,0,1-.016.2l-6.872,7.875A.228.228,0,0,1,1134.059,1238.716Z"
        transform="translate(-5.629 -8.19)"
        fill="#fff"
        fillRule="evenodd"
      />
      <path
        d="M1152.084,1235.637a1.006,1.006,0,0,1-.447.772l-1.132.652c-.256.142-.447.027-.447-.257h0v-1.029a.962.962,0,0,1,.447-.773c.382-.217.749-.436,1.132-.652.239-.142.447-.027.447.259h0Z"
        transform="translate(-11.967 -9.687)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1151.707,1234.356a.177.177,0,0,0-.1.036l-1.131.652a.792.792,0,0,0-.352.611v1.029c0,.071.016.114.032.124s.064.006.127-.031l1.133-.652a.793.793,0,0,0,.351-.61v-1.028c0-.074-.016-.116-.032-.128a.117.117,0,0,0-.032,0Zm-1.531,2.832a.432.432,0,0,1-.208-.055.494.494,0,0,1-.223-.449v-1.029a1.184,1.184,0,0,1,.542-.936l1.133-.652a.483.483,0,0,1,.494-.03.491.491,0,0,1,.223.451v1.028a1.206,1.206,0,0,1-.526.935l-1.131.653A.614.614,0,0,1,1150.175,1237.188Z"
        transform="translate(-11.844 -9.567)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1152.084,1216.49a1.008,1.008,0,0,1-.447.772l-1.132.652c-.256.142-.447.026-.447-.259v-1.028a.961.961,0,0,1,.447-.772c.382-.217.749-.435,1.132-.651.239-.143.447-.027.447.257Z"
        transform="translate(-11.967 -2.193)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1151.707,1215.208a.192.192,0,0,0-.1.037l-1.131.652a.789.789,0,0,0-.352.609v1.031a.178.178,0,0,0,.032.126c.016.01.064,0,.127-.032l1.133-.653a.79.79,0,0,0,.351-.608v-1.03c0-.072-.016-.115-.032-.126a.109.109,0,0,0-.032-.005Zm-1.531,2.834a.442.442,0,0,1-.208-.055.5.5,0,0,1-.223-.451v-1.029a1.177,1.177,0,0,1,.542-.934l1.133-.653a.481.481,0,0,1,.494-.031.493.493,0,0,1,.223.45v1.03a1.208,1.208,0,0,1-.526.935l-1.131.652A.616.616,0,0,1,1150.175,1218.042Z"
        transform="translate(-11.844 -2.073)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1152.084,1229.255a1,1,0,0,1-.447.771c-.383.217-.75.436-1.132.653-.256.142-.447.026-.447-.258h0v-1.03a.958.958,0,0,1,.447-.772c.382-.219.749-.435,1.132-.652.239-.142.447-.027.447.257h0Z"
        transform="translate(-11.967 -7.189)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1151.707,1227.974a.169.169,0,0,0-.1.037l-1.131.65a.793.793,0,0,0-.352.611v1.03c0,.072.016.115.032.124s.064.007.127-.031l1.133-.652a.789.789,0,0,0,.351-.609v-1.03c0-.072-.016-.114-.032-.126a.117.117,0,0,0-.032,0Zm-1.531,2.831a.441.441,0,0,1-.208-.054.5.5,0,0,1-.223-.451v-1.028a1.18,1.18,0,0,1,.542-.935l1.133-.652a.485.485,0,0,1,.494-.032.5.5,0,0,1,.223.452v1.03a1.205,1.205,0,0,1-.526.935l-1.131.652A.626.626,0,0,1,1150.175,1230.805Z"
        transform="translate(-11.844 -7.068)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1152.084,1222.873a1,1,0,0,1-.447.771c-.383.217-.75.436-1.132.653-.256.142-.447.026-.447-.257h0v-1.03a.964.964,0,0,1,.447-.773c.382-.217.749-.434,1.132-.651.239-.142.447-.027.447.256h0Z"
        transform="translate(-11.967 -4.691)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1151.707,1221.591a.187.187,0,0,0-.1.035l-1.131.652a.792.792,0,0,0-.352.611v1.03c0,.072.016.115.032.126s.064,0,.127-.032l1.133-.652a.788.788,0,0,0,.351-.609v-1.031c0-.071-.016-.115-.032-.125a.109.109,0,0,0-.032,0Zm-1.531,2.832a.44.44,0,0,1-.208-.054.493.493,0,0,1-.223-.45v-1.03a1.178,1.178,0,0,1,.542-.935l1.133-.651a.484.484,0,0,1,.494-.032.5.5,0,0,1,.223.451v1.031a1.2,1.2,0,0,1-.526.934l-1.131.653A.624.624,0,0,1,1150.175,1224.423Z"
        transform="translate(-11.844 -4.571)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1121.567,1229.482l-1.771-1.022v17.055l1.771,1.022Z"
        transform="translate(-0.122 -7.406)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1119.859,1245.289l1.395.8v-16.621l-1.395-.806v16.623Zm1.587,1.317a.223.223,0,0,1-.1-.026l-1.77-1.022a.187.187,0,0,1-.1-.163V1228.34a.184.184,0,0,1,.1-.16.181.181,0,0,1,.185,0l1.777,1.021a.214.214,0,0,1,.1.162v17.056a.191.191,0,0,1-.191.188Z"
        transform="translate(0 -7.286)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1125.474,1225.773l-1.77-1.02-3.908,2.256,1.771,1.022Z"
        transform="translate(-0.122 -5.955)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1120.05,1226.889l1.4.805,3.524-2.041-1.388-.8-3.532,2.039Zm1.4,1.211a.215.215,0,0,1-.1-.025l-1.77-1.022a.185.185,0,0,1,0-.324l3.907-2.256a.2.2,0,0,1,.191,0l1.77,1.021a.184.184,0,0,1,0,.323l-3.907,2.258A.206.206,0,0,1,1121.446,1228.1Z"
        transform="translate(0 -5.835)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1126.251,1231.4a.966.966,0,0,1-.447.772l-1.133.652c-.239.142-.446.028-.446-.258v-1.028a1,1,0,0,1,.446-.772c.383-.217.75-.435,1.133-.652.255-.142.447-.027.447.257Z"
        transform="translate(-1.856 -8.028)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1125.9,1230.116a.243.243,0,0,0-.112.035l-1.132.652a.8.8,0,0,0-.35.611v1.029c0,.073.015.116.032.127s.064,0,.128-.032l1.131-.652a.794.794,0,0,0,.352-.61v-1.03c0-.072-.016-.116-.032-.126,0,0-.016-.005-.016-.005Zm-1.531,2.833a.394.394,0,0,1-.207-.054.5.5,0,0,1-.223-.451v-1.029a1.161,1.161,0,0,1,.526-.935l1.131-.652a.513.513,0,0,1,.511-.032.5.5,0,0,1,.223.451v1.03a1.179,1.179,0,0,1-.542.935l-1.132.651A.558.558,0,0,1,1124.369,1232.949Z"
        transform="translate(-1.744 -7.907)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1126.251,1250.545a.962.962,0,0,1-.447.771l-1.133.652c-.239.142-.446.027-.446-.257v-1.03a1.006,1.006,0,0,1,.446-.772c.383-.217.75-.435,1.133-.652.255-.143.447-.027.447.257Z"
        transform="translate(-1.856 -15.522)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1125.9,1249.263a.242.242,0,0,0-.112.037l-1.132.653a.79.79,0,0,0-.35.609v1.03c0,.072.015.115.032.125s.064.006.128-.03l1.131-.652a.789.789,0,0,0,.352-.609v-1.03c0-.072-.016-.116-.032-.126,0,0-.016-.005-.016-.005Zm-1.531,2.832a.4.4,0,0,1-.207-.054.49.49,0,0,1-.223-.45v-1.03a1.16,1.16,0,0,1,.526-.934l1.131-.653a.513.513,0,0,1,.511-.03.492.492,0,0,1,.223.45v1.03a1.179,1.179,0,0,1-.542.934l-1.132.651A.558.558,0,0,1,1124.369,1252.1Z"
        transform="translate(-1.744 -15.401)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1126.251,1244.16a.965.965,0,0,1-.447.773c-.383.216-.749.434-1.133.652-.239.142-.446.026-.446-.258h0V1244.3a1.009,1.009,0,0,1,.446-.773c.383-.217.75-.433,1.133-.652.255-.142.447-.025.447.259h0Z"
        transform="translate(-1.856 -13.023)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1125.9,1242.881a.23.23,0,0,0-.112.035l-1.132.652a.793.793,0,0,0-.35.61v1.028c0,.072.015.117.032.127a.141.141,0,0,0,.128-.03l1.131-.653a.792.792,0,0,0,.352-.61v-1.028c0-.072-.016-.117-.032-.127l-.016,0Zm-1.531,2.832a.391.391,0,0,1-.207-.055.493.493,0,0,1-.223-.451v-1.028a1.16,1.16,0,0,1,.526-.934l1.131-.652a.514.514,0,0,1,.511-.032.494.494,0,0,1,.223.451v1.028a1.172,1.172,0,0,1-.542.934l-1.132.653A.557.557,0,0,1,1124.369,1245.713Z"
        transform="translate(-1.744 -12.903)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
      <path
        d="M1126.251,1237.78a.957.957,0,0,1-.447.77c-.383.219-.749.437-1.133.654-.239.142-.446.025-.446-.258h0v-1.031a1.006,1.006,0,0,1,.446-.771c.383-.218.75-.435,1.133-.652.255-.142.447-.026.447.258h0Z"
        transform="translate(-1.856 -10.525)"
        fill="#195bb6"
        fillRule="evenodd"
      />
      <path
        d="M1125.9,1236.5a.237.237,0,0,0-.112.035l-1.132.653a.792.792,0,0,0-.35.609v1.031c0,.07.015.115.032.125a.137.137,0,0,0,.128-.03l1.131-.652a.794.794,0,0,0,.352-.609v-1.03c0-.072-.016-.116-.032-.125,0,0-.016-.005-.016-.005Zm-1.531,2.831a.4.4,0,0,1-.207-.054.5.5,0,0,1-.223-.452v-1.03a1.157,1.157,0,0,1,.526-.932l1.131-.653a.51.51,0,0,1,.511-.031.5.5,0,0,1,.223.451v1.03a1.172,1.172,0,0,1-.542.932l-1.132.652A.549.549,0,0,1,1124.369,1239.33Z"
        transform="translate(-1.744 -10.405)"
        fill="#e6e6e6"
        fillRule="evenodd"
      />
    </g>
  </svg>
)
